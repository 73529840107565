<template>
    <div class="problem">
        <div class="header">
            <div class="left">
                <van-icon name="arrow-left" @click="gopath('back')" class="icon" />
            </div>
            <div class="title">设置</div>
        </div>
        <div class="list">
            <div class="item" @click="gopath('/changephone')">
                <div class="key">手机号</div>
                <div class="val">
                    <span>更改</span>
                    <van-icon name="arrow" />
                </div>
            </div>
            <div class="item" @click="gopath('/retpassword')">
                <div class="key">修改密码</div>
                <div class="val">
                    <van-icon name="arrow" />
                </div>
            </div>
            <div class="item" @click="gopath('/destroy')">
                <div class="key">账号注销</div>
                <div class="val">
                    <van-icon name="arrow" />
                </div>
            </div>
            <div class="item" @click="logout">
                <div class="key">退出登录</div>
                <div class="val">
                    <van-icon name="arrow" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { destroy } from "@/api/user.js";
export default {
    data() {
        return {};
    },
    created() { },
    methods: {
        gopath(val) {
            if (val != "back") {
                this.$router.push({ path: val });
            } else {
                this.$router.go(-1);
            }
        },
        logout() {
            this.$dialog.confirm({
                message:'是否退出登录？'
            }).then((res)=>{
                localStorage.clear()
                this.$router.push('/my')
            }).catch((err)=>{})

        },
    },
};
</script>

<style lang="less" scoped>
.header {
    padding: 10px 10px;
    display: flex;
    .left {
        width: 10%;
        .icon {
            font-size: 24px;
            font-weight: 550;
            color: #141414;
        }
    }
    .title {
        width: 85%;
        text-align: center;
        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
    }
}
.list {
    padding: 0 14px;
    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dddddd;
        padding: 12px 0;
        .key {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #141414;
            line-height: 20px;
        }
        .val {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4c6cf4;
            line-height: 18px;
            .van-icon {
                color: #141414;
            }
        }
    }
}
</style>